<template>
  <page-container>
    <div class="project-container">
      <div
        class="hero"
        :style="{
          backgroundImage: `url(${coverImage})`,
        }"
      ></div>

      <h4 class="title">{{ project.name }}</h4>

      <p class="description">
        {{ project.intro }}
      </p>

      <div class="services">
        <div class="header">
          <h3 class="header-title">Services</h3>
          <div class="header-background" :class="project.class"></div>
        </div>
        <p
          class="service"
          v-for="(service, index) in project.services"
          :key="index"
        >
          {{ service }}
        </p>
      </div>

      <div class="project-image-container first">
        <img
          :src="logoImage"
          class="project-image"
          id="img-1"
          alt="project-image"
          loading="lazy"
        />
        <div class="cover-top"></div>
        <div class="cover-bottom"></div>
      </div>

      <div class="project-image-container">
        <img
          :src="promoImage"
          class="project-image img-2"
          alt="project-image"
          loading="lazy"
        />
      </div>

      <p class="description">
        {{ project.brief[0] }}
      </p>

      <div
        class="project-image-container"
        v-if="!project.name.startsWith('Kunashe')"
      >
        <img
          :src="brandingImage"
          class="project-image"
          alt="project-image"
          loading="lazy"
        />
      </div>

      <div class="badge-container" v-else>
        <div class="row-1" v-show="!skinnyMobile">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Fchicken-sticker.png?alt=media&token=2cf50651-d5a2-45b2-9bb4-68e0edb32ee0"
            class="badge"
            alt="chicken-sticker"
            loading="lazy"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Fpork-sticker.png?alt=media&token=9422a8f5-a874-4a92-882d-65f8321c555e"
            class="badge"
            alt="pork-sticker"
            loading="lazy"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Feggs-sticker.png?alt=media&token=1bc6c259-41a1-4bff-a2e6-3d4874d45d63"
            class="badge"
            alt="eggs-sticker"
            loading="lazy"
          />
        </div>
        <div class="row-2" v-show="!skinnyMobile">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Ffruits-sticker.png?alt=media&token=3f75efef-236c-4bb8-b7a6-039bf8359611"
            class="badge"
            alt="fruits-sticker"
            loading="lazy"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Fveggie-sticker.png?alt=media&token=f174ea58-02a9-4c86-97ce-a3fcbcec1c8e"
            class="badge"
            alt="veggie-sticker"
            loading="lazy"
          />
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/imgs%2Fbadges.jpg?alt=media&token=24cd2770-a800-498d-add7-0b9806fe72a4"
          alt="stickers"
          class="sticker-img"
          v-show="skinnyMobile"
          loading="lazy"
        />
      </div>

      <p class="description">
        {{ project.brief[1] }}
      </p>

      <image-slider v-if="skinnyMobile" :project="project"></image-slider>

      <carousel
        :project="project"
        :numImages="project.carousel.length"
        v-else
      ></carousel>

      <p class="description mg-med">{{ project.brief[2] }}</p>

      <scroll-top></scroll-top>
    </div>
  </page-container>
</template>

<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import Carousel from '@/components/Carousel.vue';
import ImageSlider from '@/components/ImageSlider.vue';

gsap.registerPlugin(ScrollTrigger);

export default {
  props: ['projectId'],

  components: {
    Carousel,
    ImageSlider,
  },

  data() {
    return {
      skinnyMobile: false,
    };
  },

  computed: {
    project() {
      return this.PROJECTS().find((el) => el.name === this.projectId);
    },

    coverImage() {
      if (this.skinnyMobile) {
        return this.project.cover.mobile.story;
      }
      return this.project.cover.desktop;
    },

    logoImage() {
      if (this.skinnyMobile) {
        return this.project.images[0].mobile;
      }
      return this.project.images[0].desktop;
    },

    promoImage() {
      if (this.skinnyMobile) {
        return this.project.images[1].mobile;
      }
      return this.project.images[1].desktop;
    },

    brandingImage() {
      if (this.skinnyMobile) {
        return this.project.images[2].mobile;
      }
      return this.project.images[2].desktop;
    },
  },

  methods: {
    ...mapGetters(['PROJECTS']),

    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 540) {
        this.skinnyMobile = true;
        return;
      }
      this.skinnyMobile = false;
    },
  },

  mounted() {
    gsap.to(['.cover-top', '.cover-bottom'], {
      width: 0,
      duration: 1.8,
      scrollTrigger: {
        id: 'trigger1',
        trigger: '#img-1',
        start: '42.5% 95%',
      },
    });
  },

  created() {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.project-container {
  .badge-container {
    margin-bottom: 10rem;

    @media screen and (max-width: 540px) {
      margin-bottom: 6rem;
    }

    .badge {
      width: 19.3rem;

      &:not(:last-of-type) {
        margin-right: 8rem;
      }
    }

    .row-2 {
      margin-top: 8rem;
      text-align: center;
    }

    .sticker-img {
      width: 100%;
    }
  }

  .first {
    margin-bottom: 0 !important;
  }

  .img-2 {
    object-position: top !important;
  }
}

.top {
  bottom: 5rem !important;
}
</style>